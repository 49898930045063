import React, { useEffect, useState, useContext, FormEvent } from 'react';
import { Button, TextField, Select, MenuItem, Typography, CircularProgress, FormControl, InputLabel, Box, Grid, Dialog, DialogContent, Drawer } from '@mui/material';
import jwt_decode from "jwt-decode";
import storageApiService from '../../services/api/storageApiService';
import AuthService from '../../services/AuthService';
import { ItemType, ItemTypeAdded, PostBody } from '../models/itemAndLocation';
import inventoryApiService from "../../services/api/inventoryApiService";
// import Cookies from 'universal-cookie';
import { Scanner } from '@yudiel/react-qr-scanner';
import { DiscountModel } from '../models/discountModel';
// import { getLocationsAndItemTypes, postNewItemToStorage, postQRcode, getItemsFromStorage } from '../../Services/Api/StorageApi';
  
const NewItem: React.FC = () => {

  const [location, setLocation] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [itemList, setItemList] = useState<ItemType[]>([]);
  const [selectedItemList, setSelectedItemList] = useState<ItemTypeAdded[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSlimmer, setIsSlimmer] = useState<boolean>(false);
  const [userData, setUserData] = useState<{ [key: string]: string }>({});
  const [itemsTitle, setItemsTitle] = useState<string>("Nincs hozzáadott termék");
  const [isWarrantyPurchase, setIsWarrantyPurchase] = useState<boolean>(false);
  const [imei, setImei] = useState<string>("");
  const [showDiscountQrReader, setShowDiscountQrReader] = useState<boolean>(false);
  const [discount, setDiscount] = useState<DiscountModel>();
  const [showQrReader, setShowQrReader] = useState<boolean>(false);
  const [showQrReaderItem, setShowQrReaderItem] = useState<boolean>(false);

  const include = selectedItemList.some((val) => val.itemType.includes("kártya"));

  const fetchData = async () => {
    const data = await storageApiService.fetchItemTypes();
    setItemList(data);
     setLocation(AuthService.getBranchName() ?? "");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatPrice = (value: number) => {
    return value.toLocaleString('hu') + " Ft";
  };

  const getPrice = (itemType: string) => {
    const item = itemList.find((element) => element.itemName === itemType);
    const itemPrice = item ? (isWarrantyPurchase ? 6000 : isSlimmer ? item.slimmerPrice : item.regularPrice) : 0;
    switch (itemPrice) {
      case 21000:
        return 18000;
      case 14000:
        return 12000;
      case 7000:
        return 6000;
      case 7500:
        return 6000;
      case 15000:
        return 12000;
      case 22500:
        return 18000;
      default:
        return itemPrice;
    }
  };

  const getAllPrice = () => {
    let totalPrice = 0;
    if (isWarrantyPurchase) {
      if (selectedItemList.length > 0) {
        totalPrice = 6000;
      }
    } else {
      totalPrice += selectedItemList.reduce((acc, item) => {
        const itemPrice = getPrice(item.itemType);
        return acc + (itemPrice || 0);
      }, 0);
    }
    if (discount) {
      if (discount.discountType === "percent") {
        totalPrice = totalPrice - (totalPrice * (parseInt(discount.amount) / 100));
      } else if (discount.discountType === "fixed") {
        totalPrice = totalPrice - parseInt(discount.amount);
      }}
    return totalPrice;
  };
  function encodeData(data: string): string {
    // UTF-8 encoding of the input string
    const utf8Data = new TextEncoder().encode(data);
  
    // Convert the UTF-8 data to a binary string
    const binaryString = Array.from(utf8Data).map(byte => String.fromCharCode(byte)).join('');
  
    // Base64 encoding
    const base64 = btoa(binaryString);
  
    // URL-friendly Base64
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  function decodeData(base64: string) {
    // URL-barát base64 visszaalakítása normál base64 formátumra
    let base64String = base64.replace(/-/g, '+').replace(/_/g, '/');
    // Base64 dekódolás
    let utf8Data = atob(base64String);
    // UTF-8 dekódolás
    return decodeURIComponent(escape(utf8Data));
  }
  const handleScan = (data: string) => {
    if (data) {
      let decodedData = decodeData(data)
      let json = JSON.parse(decodedData)
      if (json["Name"]) {
        let time = new Date();
        time.setHours(time.getHours() + 1);
        let year = time.getFullYear();
        let month = String(time.getMonth() + 1).padStart(2, '0');
        let day = String(time.getDate()).padStart(2, '0');
        let formattedDate = `${year}-${month}-${day}`;
            setUserData(json)
            setEmail(json["Email"])
            setIsSlimmer(true)
            setShowQrReader(false)
        
      } else {
        setIsSlimmer(false)
        setEmail("")
        alert("Érvénytelen Slimmer QR kód!")
        setShowQrReader(false)
      }
  
    }
  };
  const handleScanItem = (data: string) => {
    if (data) {
      if (data.length < 80) {
        if(data.includes("-") && data !== "Slim Self-healing") {
          let splitData = data.split("-")
          handleAddItem(splitData[0].slice(0, -1), splitData[1].slice(1));
          setShowQrReaderItem(false)
        } else {
          handleAddItem(data, "-");
          setShowQrReaderItem(false)
        }
      } else {
        window.alert("Hibás QR termékkód!")
        setShowQrReaderItem(false)
      }
    }
  };

  const handleScanDiscountItem = (data: string) => {
  
    // Decode the Base64 string
    const decodedString = atob(data);

    // Parse the decoded string into an object
    const discountData: DiscountModel = JSON.parse(decodedString);

    if (discountData.discountType === "percent" || discountData.discountType === "fixed") {
      setDiscount(discountData);
      setShowDiscountQrReader(false);
    } else {
      window.alert("Hibás QR termékkód!")
      setShowDiscountQrReader(false)
    }
  };
  const handleAddItem = (itemType: string, mobileType: string) => {
    if (isWarrantyPurchase && selectedItemList.length > 0) {
      alert("Garanciális cserét csak egy termékre tudsz felvinni!");
      return;
    }
    const newItem: ItemTypeAdded = { id: selectedItemList.length + 1, itemType, mobileType };
    setSelectedItemList([...selectedItemList, newItem]);
    setItemsTitle("Hozzáadott termékek:");
  };

  const handleRemove = (id: number) => {
    const items = selectedItemList.filter((item) => item.id !== id);
    setSelectedItemList(items);
    setItemsTitle(items.length > 0 ? "Hozzáadott termékek:" : "Nincs hozzáadott termék");
  };


  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
      if (selectedItemList.length > 0) {
        var selectedItemListNew = selectedItemList.map(val => {
          val.itemPrice = getPrice(val.itemType);
          return val;
        });
        const cardStrings: string[] = [];
        var index = 1;
        selectedItemListNew.forEach(element => {
          if(element.itemType.includes("kártya")) {
            var data1 = {id: index, location: location, cardType: element.itemType, price: getPrice(element.itemType), timestamp: new Date().toISOString()}
        //TODO: Implement encodeData
        const encodedData = encodeData(JSON.stringify(data1)); // encoding JSON string
        cardStrings.push(encodedData);
          index = index + 1;
          }
        });

        if(include && email === "") {
          alert("Kérlek add meg a kártyához tartozó e-mail címet!")
          return
        }
        var orderInfo = ""
        selectedItemListNew.forEach(element => {
          orderInfo = orderInfo + " | "
          orderInfo = orderInfo + element.itemType
          orderInfo = orderInfo + " - "
          if(element.mobileType !== "-") {
            orderInfo = orderInfo + element.mobileType
            orderInfo = orderInfo + " - "
          }
          orderInfo = orderInfo + element.itemPrice + " Ft"
          orderInfo = orderInfo + " | "
        });
        if(type === "") {
            alert("Kérlek válassz fizetési módot!")
            return
            }
        if (window.confirm("Biztosan felviszed ezt a rendelést? | " + location + " - " + type + orderInfo + " Összesen: " + getAllPrice() + " Ft")) {
          setIsLoading(true);

        const today = new Date();
        const stringDate = 
          today.getFullYear() + '-' + 
          (today.getMonth() + 1) + '-' + 
          today.getDate() + ' ' + 
          today.getHours() + ':' + 
          today.getMinutes() + ':' + 
          today.getSeconds();

        // Fő adatot tartalmazó body elkészítése
        const body: PostBody = {
          time: stringDate,
          location: location,
          price: getAllPrice(),
          paymentType: type,
          userid: parseInt(AuthService.getUserId()!),
          items: selectedItemList,
          warrantyReplace: "",
          slimmerEmail: "",
          discountType: discount?.discountType,
          discountAmount: discount?.amount,
        };

        if (isWarrantyPurchase) {
          body.warrantyReplace = imei;
        }

        if (isSlimmer) {
          body.slimmerEmail = userData["Email"];
        }

        // Az összes hívást külön ígéretként definiáljuk
        const postNewItemPromise = storageApiService.postNewItemToStorage(body);

        const postQrPromises = include ? cardStrings!.map((item) => {
          const body = { qrstring: item, email: email };
          return storageApiService.postQrCode(body);
        }) : [];

        const decrementPromises = selectedItemList.map((element) => {
          const itemId = itemList.find((item) => item.itemName === element.itemType)?.id;
          return inventoryApiService.decrementInventoryQuantity(AuthService.getBranchId()!, itemId!.toString(), element.mobileType);
        });

        // Promise.all-lal futtatjuk az összeset, így mindhárom típusú kérés futni fog
        await Promise.all([
          postNewItemPromise,
          ...postQrPromises,
          ...decrementPromises
        ]);

        setIsLoading(false);
       }
       
      } else {
        alert("Adj hozzá legalább 1 terméket!")
      }
      
    }
    const handlePaymentTypeChange = (newType: string) => {
        setType(newType);
      };
    return (
        <Box p={3} sx={{ maxWidth: 600, mx: 'auto', backgroundColor: '#fff', borderRadius: 2, boxShadow: 3 }}>
          <Typography variant="h4" color="primary" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
            Új eladás felvitele
          </Typography>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowQrReader(!showQrReader)}
                fullWidth
                sx={{ borderRadius: 3, fontWeight: 'bold' }}
              >
                {showQrReader ? 'Slimmer szkenner bezárása' : 'Slimmer szkenner megnyitása'}
              </Button>
              {isSlimmer && (
                <TextField label="Slimmer email" value={userData["Email"]} disabled fullWidth variant="outlined" sx={{ mt: 2, '& .MuiOutlinedInput-root': {
                    borderRadius: 3,
                  } }} />
              )}
              <Drawer anchor="bottom" open={showQrReader} onClose={() => setShowQrReader(false)}>
                <Box p={2}>
                  <Scanner onScan={(result) => handleScan(result[0].rawValue)} onError={(error) => console.error("QR Code error:", error)} />
                </Box>
              </Drawer>
            </Grid>
            
            <Grid item>
              <TextField
                label="Helyszín"
                value={location}
                disabled
                fullWidth
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 3,
                  }
                }}
              />
            </Grid>
    
            <Grid item>
              {selectedItemList.length > 0 ? (
                <Box>
                  <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
                    Hozzáadott termékek
                  </Typography>
                  <Box display="flex" flexWrap="wrap" justifyContent="center" gap={1}>
                    {selectedItemList.map((item) => (
                      <Box
                        key={item.id}
                        display="flex"
                        alignItems="center"
                        bgcolor="#e0f7fa"
                        p={1}
                        borderRadius={20}
                        boxShadow={1}
                        sx={{
                          px: 2,
                          color: "primary.main",
                          fontWeight: 'bold',
                        }}
                      >
                        <Typography variant="body2" sx={{ mr: 1 }}>
                          {item.itemType} - {item.mobileType}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleRemove(item.id)}
                          sx={{
                            minWidth: 'auto',
                            padding: '4px',
                            borderRadius: '100%',
                            height: '20px',
                            width: '20px',
                          }}
                        >
                          ×
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    borderColor: 'primary',
                    color: 'primary',
                    borderRadius: 3,
                    fontWeight: 'bold',
                  }}
                >
                  Nincs hozzáadott termék
                </Button>
              )}
            </Grid>
    
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowQrReaderItem(!showQrReaderItem)}
                fullWidth
                sx={{ borderRadius: 3, fontWeight: 'bold' }}
              >
                Termék hozzáadása
              </Button>
              <Drawer anchor="bottom" open={showQrReaderItem} onClose={() => setShowQrReaderItem(false)}>
                <Box p={2}>
                  <Scanner onScan={(result) => handleScanItem(result[0].rawValue)} onError={(error) => console.error("QR Code error:", error)} />
                </Box>
              </Drawer>
            </Grid>
           {selectedItemList.length > 0 && <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowDiscountQrReader(!showDiscountQrReader)}
                fullWidth
                sx={{ borderRadius: 3, fontWeight: 'bold' }}
              >
                Kedvezmény kód beolvasása
              </Button>
              <Drawer anchor="bottom" open={showDiscountQrReader} onClose={() => setShowDiscountQrReader(false)}>
                <Box p={2}>
                  <Scanner onScan={(result) => handleScanDiscountItem(result[0].rawValue)} onError={(error) => console.error("QR Code error:", error)} />
                </Box>
              </Drawer>
            </Grid>}
            {include && !isSlimmer && (
                <Grid item>
  <TextField
    label="E-mail"
    value={email}
    onChange={(e) => setEmail(e.target.value)} // Update email state on change
    fullWidth
    variant="outlined"
    sx={{
      '& .MuiOutlinedInput-root': {
        borderRadius: 3,
      }
    }}
  />
  </Grid>
)}
<Grid item>
          <Box display="flex" justifyContent="center" gap={2}>
            <Button
              variant={type === "Készpénz" ? "contained" : "outlined"}
              color="primary"
              onClick={() => handlePaymentTypeChange("Készpénz")}
            >
              Készpénzes Fizetés
            </Button>
            <Button
              variant={type === "Bankkártya" ? "contained" : "outlined"}
              color="primary"
              onClick={() => handlePaymentTypeChange("Bankkártya")}
            >
              Bankkártyás Fizetés
            </Button>
          </Box>
        </Grid>
            <Grid item>
              <Typography variant="h6" color="primary" align="center" sx={{ fontWeight: 'bold' }}>
                {discount ? `Kedvezmény: ${discount.amount} ${discount.discountType === "percent" ? "%" : "Ft"}` : "Nincs kedvezmény"}<br></br>
                Összesen: {getAllPrice()} Ft
              </Typography>
            </Grid>
    
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={isLoading}
                sx={{ borderRadius: 3, fontWeight: 'bold' }}
              >
                Adat felvitele
              </Button>
              {isLoading && <CircularProgress sx={{ color: "green", mt: 2 }} />}
            </Grid>
          </Grid>
        </Box>
      );
};

export default NewItem;